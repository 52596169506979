import React from "react";
import {FaCheck} from 'react-icons/fa'
import {FaUser} from 'react-icons/fa'
import {ImTruck} from 'react-icons/im'
import {BsFillBoxFill} from 'react-icons/bs'

import "../css/stepperorder.css";

function StepperOrder() {
  return (
    <div>
      

      <div
        className="line_box stav_projektu"
        style={{ margin: "40px 0px 40px 0px" }}
      >
        <div className="text_circle sub">
          <a href="javascript:void(0)" className="tvar">
            <span
              data-toggle="popover"
              title="Sample text"
              data-trigger="hover"
              data-placement="top"
              data-content="Ukázkový text při hover"
            > <FaCheck className="check-icon"/> </span>
          </a>
          <div className="circle">
            <h4>Order Confirm</h4>
            <p>TIme : 09:30 PM</p>
          </div>
        </div>
        
        <div className="text_circle ">
          
          <a href="javascript:void(0)" className="tvar">
            <span
              data-toggle="popover"
              title="Sample text"
              data-trigger="hover"
              data-placement="top"
              data-content="Ukázkový text při hover"
            > <FaUser className="check-icon"/> </span>
          </a>
          <div className="circle">
            <h4>Picked By Courier</h4>
            <p>Time 10:30 PM</p>
          </div>
        </div>
        
        
        <div className="text_circle ">
          
          <a href="javascript:void(0)" className="tvar">
          <span
              data-toggle="popover"
              title="Sample text"
              data-trigger="hover"
              data-placement="top"
              data-content="Ukázkový text při hover"
            ><ImTruck className="check-icon"/></span>
          </a>
          <div className="circle">
            <h4>On the Way</h4>
            <p>Time 12:00 PM</p>
          </div>
        </div>
        
        <div className="text_circle">
        <a href="javascript:void(0)" className="tvar">
          <span
              data-toggle="popover"
              title="Sample text"
              data-trigger="hover"
              data-placement="top"
              data-content="Ukázkový text při hover"
            ><BsFillBoxFill className="check-icon"/></span>
          </a>
          <div className="circle">
            <h4>Ready For Pickup</h4>
            <p>Time 02:30 PM</p>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default StepperOrder;
