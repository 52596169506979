import React, { useState } from "react";
import { BsList } from "react-icons/bs";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import "../css/navbar.css";

function NavHeader() {
  const navigation = useNavigate()
  const [openSidemenu, setOpenSideMenu] = useState(false);
  return (
    <>
      {openSidemenu === true ? <SideMenu /> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <div className="logo">
              <img className="pointer" src="/img/logo.png" alt="" onClick={()=>navigation('/')} />
              <BsList
                onClick={() => setOpenSideMenu(!openSidemenu)}
                className="hemburgun-icon"
              />
            </div>
          </div>

          <div className="col-md-8">
            <div className="category-list-navbar">
              <ul>
                <li>Home </li>
                <li>About us</li>
                <li onClick={()=>navigation('/new-order')}>Express Delivery</li>
                <li onClick={()=>navigation('/order-history')} >Track Shipment </li>
                <li onClick={()=>navigation('/contact')} >Contact Us</li>
              </ul>
            </div>
          </div>

          <div className="col-md-2">
            <div className="navbar-btns">
              <button className="btn-login"  onClick={()=>navigation('/login')} >Log in</button>
              <button className="btn-signup"  onClick={()=>navigation('/signup')}>Sign up</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavHeader;
