import React from 'react'
import '../css/sidemenu.css'

function SideMenu() {
  return (
    <div className="sidemenu">
        <div className="sidemenu-main">
            <ul>
                <li>Home</li>
                <li>About us</li>
                <li>Express Delivery</li>
                <li>Track Shipment </li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
  )
}

export default SideMenu
