import React from "react";
import TopHeader from "../components/TopHeader";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import "../css/contact.css";

function Contact() {
  return (
    <>
      <TopHeader />
      <NavHeader />
      <div className="container">
        <h5 className="my-5">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore
          delectus excepturi amet nisi ab dolor ut expedita provident iure
          inventore. Debitis, ducimus dolor aperiam ipsam nesciunt vero culpa
          doloremque nemo!
        </h5>
        <div className="row">
          <div className="col-md-6">
            <div className="reach">
              <h4>Reach Us</h4>
              <div className="address-box">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">Email </div>
                    <div className="col-md-1 ">:</div>
                    <div className="col-md-7">coolrunning@gmail.com</div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Phone </div>
                    <div className="col-md-1">:</div>
                    <div className="col-md-7">+1(234) 545 3434</div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Address </div>
                    <div className="col-md-1">:</div>
                    <div className="col-md-7">
                      A-6 sector 16 noida uttar pradesh A-6 sector 16 noida
                      uttar pradesh{" "}
                    </div>
                  </div>
                </div>
              </div>

              <h4>Customer Support</h4>

              <div className="support-box">
                <div className="support-first">
                  <img src="/img/wp.png" alt="" />
                  <p>+1(343) 3443 4543</p>
                </div>
                <div className="support-second">
                  <img src="/img/msg.png" alt="" />
                  <p>coolrunning@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="request">
              <h4>Send Your Request</h4>

              <div className="form">
                <div className="contact-input-fields  my-2">
                  <div className="contact-input-single  my-2">
                    <label htmlFor="fname" className="mb-2">Name</label>
                    <input type="text" id="fname" placeholder="Name" />
                  </div>

                  <div className="contact-input-single  my-2">
                    <label htmlFor="phone" className="mb-2">Phone</label>
                    <input type="text" id="phone" placeholder="Phone" />
                  </div>
                </div>

                <div className="contact-input-fields  my-2">
                  <div className="contact-input-single my-2">
                    <label htmlFor="email" className="mb-2">Email</label>
                    <input type="email" id="email" placeholder="Email" />
                  </div>

                  <div className="contact-input-single my-2">
                    <label htmlFor="subject" className="mb-2" >Subject</label>
                    <input type="text" id="subject" placeholder="Subject" />
                  </div>
                </div>

                <div className="contact-single-input  my-2">
                  <label htmlFor="Message" className="mb-2">Message</label>
                  <textarea
                    className="contact-neworder-textarea"
                    type="Type something..."
                    id="Message"
                  />
                </div>
                <button className="contact-button" >SEND</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
