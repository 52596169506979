import React from 'react'
import '../css/shippingservice.css'

function ShippingService() {
  return (
   <div className="container my-4">
    <h2 className='shipping-title' >Choose a shipping Services</h2>
      <div className="row">
         <div className="col-md-4">
            <div className="shipping">
                <img src="/img/tick.png" alt="" />
                <h5>Regular Service</h5>
                <p>One day</p>
                <span>If Recieved before 6 PM</span>
                <button>$ 25</button>
            </div>
         </div>
         <div className="col-md-4">
            <div className="shipping">
                <img src="/img/tick.png" alt="" />
                <h5>Regular Service</h5>
                <p>One day</p>
                <span>If Recieved before 6 PM</span>
                <button>$ 25</button>
            </div>
         </div>
         <div className="col-md-4">
            <div className="shipping">
                <img src="/img/tick.png" alt="" />
                <h5>Regular Service</h5>
                <p>One day</p>
                <span>If Recieved before 6 PM</span>
                <button>$ 25</button>
            </div>
         </div>
      </div>
      
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
            <div className="book-button">
                <button>BOOK NOW</button>
            </div>
        </div>
        <div className="col-md-3"></div>
      </div>
   </div>
  )
}

export default ShippingService
