import React from 'react'
import '../css/slider.css'

function Slider() {
  return (
    <div className='container' >
        <div className="row">
            <div className="col-md-6">
                <div className="slider-first">
                    <h4>The New Parcel</h4>
                    <h1>Of Delivery System in <span>the word</span></h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nobis id,
                    quaerat quos unde repellendus assumenda quidem deleniti officia voluptate?</p>
                    <div className="tracking-input">
                        <input type="text" placeholder='Type of your Tracking Number' />
                        <button className='btn-track' >track</button>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="slider-img">
                    <img src="/img/slider-img.png" alt="" />
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default Slider
