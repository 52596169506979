
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // Link,
} from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import NewOrder from './pages/NewOrder';
import Contact from './pages/Contact';
import Login from './components/Login';
import Signup from './components/Signup';
import OrderHistory from './pages/OrderHistory';
import OrderDetails from './pages/OrderDetails';

const router = createBrowserRouter([
  {
    path: "/",
    element: (<Home/>)
  },
  {
    path: "about",
    element:<About/>,
  },
  {
    path: "new-order",
    element:<NewOrder/>,
  },
  {
    path: "contact",
    element:<Contact/>,
  },
  {
    path: "login",
    element:<Login/>,
  },
  {
    path: "signup",
    element:<Signup/>,
  },
  {
    path: "order-history",
    element:<OrderHistory/>,
  },
  {
    path: "order-details",
    element:<OrderDetails/>,
  },
]);

function App() {
  return (
    <div>
       <RouterProvider router={router} />
    </div>
  );
}

export default App;
