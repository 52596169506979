import React from "react";
import TopHeader from "../components/TopHeader";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import StepperOrder from "../Layouts/StepperOrder";
import { BsFillCircleFill } from "react-icons/bs";
import "../css/orderdetails.css";

function OrderDetails() {
  return (
    <>
      <TopHeader />
      <NavHeader />
      <div className="container">
        <div>
          <div className="order-detail">
            <h2>My Order Details</h2>
            <div className="heading-order-details">
              <div className="order-ids">
                <BsFillCircleFill className="dot-order-icon" />
                <span>OrdderId : 385649253</span>
              </div>
              <div className="date-order">Date :08/3/2023</div>
            </div>
          </div>

          <div className="row">
            <div className="order-details-2">
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Estimated Delivery Time:</h4>
                  <p>30 Oct 2022</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Shipping By:</h4>
                  <p>BLUE DART | +7210161226</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Status:</h4>
                  <p>Picked by the courier</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Tracking:</h4>
                  <p>BD83473H834</p>
                </div>
              </div>
            </div>
          </div>
          <StepperOrder />
        </div>
        
        <div>
          <div className="order-detail">
            <h2>My Order Details</h2>
            <div className="heading-order-details">
              <div className="order-ids">
                <BsFillCircleFill className="dot-order-icon" />
                <span>OrdderId : 385649253</span>
              </div>
              <div className="date-order">Date :08/3/2023</div>
            </div>
          </div>

          <div className="row">
            <div className="order-details-2">
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Estimated Delivery Time:</h4>
                  <p>30 Oct 2022</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Shipping By:</h4>
                  <p>BLUE DART | +7210161226</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Status:</h4>
                  <p>Picked by the courier</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="order-details-main-2">
                  <h4>Tracking:</h4>
                  <p>BD83473H834</p>
                </div>
              </div>
            </div>
          </div>
          <StepperOrder />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderDetails;
