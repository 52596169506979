import React from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import "../css/orderscategories.css";

function OrdersCategories() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="orders-main">
            <img src="/img/first.png" alt="" />
            <div className="orders">
              <h3>New Order</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="orders-main-2">
            <img src="/img/shipped.png" alt="" />
            <div className="orders-2">
              <h3>Through Call</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn-2">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="orders-main">
            <img src="/img/third.png" alt="" />
            <div className="orders">
              <h3>Order History</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="order-category-title">
            <h2>
              Why You <span>Choose RSD</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
              ducimus quas, a blanditiis necessitatibus omnis eum unde magni
              illum voluptates dolore, inventore saepe possimus eveniet nemo
              laudantium totam perferendis quam fugit impedit perspiciatis?
              Dolorem, harum. Praesentium architecto rerum veritatis! Quod
              dolor, nam eum aut sunt incidunt recusandae consectetur. Neque,
              voluptas!
            </p>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default OrdersCategories;
