import React from 'react'

function About() {
  return (
    <div>
       <h3>About page</h3>
    </div>
  )
}

export default About
