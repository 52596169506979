import React,{useState} from "react";
import TopHeader from "../components/TopHeader";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import {FiSearch} from 'react-icons/fi'
import FIlterTable from "./FIlterTable";
import "../css/orderhistory.css";

function OrderHistory() {
   
    const [selected ,setSelected]  = useState('All')
  return (
    <>
      <TopHeader />
      <NavHeader />
        <div className="container">
            <div className="history-header">
                <h2>Check Order History</h2>
                 <div className="search-bar">
                    <input type="text" placeholder="Find an order"/>
                    <FiSearch/>
                 </div>
            </div>
            
            <div className="filters">
                <div className="row">
                    <div className="col-md-2">
                        <h2 className="filter-text" >Filters</h2>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('All')} className={selected==='All'? "filter-btn":'filter-btn-selected'} >All(245)</button>
                            </div>
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('Pending')} className={selected==='Pending'? "filter-btn":'filter-btn-selected'} >Pending(23)</button>
                            </div>
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('Confirm')} className={selected==='Confirm'? "filter-btn":'filter-btn-selected'} >Confirm(23)</button>
                            </div>
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('Shipped')} className={selected==='Shipped'? "filter-btn":'filter-btn-selected'} >Shipped(23)</button>
                            </div>
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('Completed')} className={selected==='Completed'? "filter-btn":'filter-btn-selected'} >Completed(07)</button>
                            </div>
                            <div className="col-md-2">
                                <button onClick={()=>setSelected('Cancelled')} className={selected==='Cancelled'? "filter-btn":'filter-btn-selected'} >Cancelled(23)</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <FIlterTable/>
        </div>
      <Footer />
    </>
  );
}

export default OrderHistory;
