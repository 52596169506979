import React from "react";
import "../css/inquiry.css";

function Inquiry() {
  return (
    <div className="inquiry">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="inquiry-main">
              <h1>Committed To Improving Turnaround And Costs</h1>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Corrupti ad suscipit et architecto repellendus, praesentium ab
                eligendi autem dolores quae commodi nihil tempora quis sapiente
                reprehenderit blanditiis aliquid explicabo quia id tempore
                alias. Ipsum, quas voluptatibus repudiandae a assumenda nihil.
              </p>
              <div className="row">
                <div className="col-md-4">
                  <div className="inquiry-second-main">
                    <img src="/img/first.png" alt="" />
                    <h3>Hello this is</h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inquiry-second-main">
                    <img src="/img/Delivery.png" alt="" />
                    <h3>Hello this is</h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inquiry-second-main">
                    <img src="/img/Close Garage Door.png" alt="" />
                    <h3>Hello this is</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inquiry-form">
              <div className="form forms">
                <h2>Request a Quote</h2>
                <div className="input-first">
                  <input type="text" placeholder="Name" />
                  <input type="text" placeholder="Email" />
                </div>
                <div className="input-first">
                  <input type="text" placeholder="Phone" />
                  <input type="text" placeholder="Subject" />
                </div>
                <div className="input-first">
                  <input type="text" placeholder="Freigth Type" />
                  <input type="text" placeholder="City of departure" />
                </div>
                <div className="input-first">
                  <input type="text" placeholder="Delivery City" />
                  <input type="text" placeholder="" />
                </div>
                <div className="input-first">
                  <input type="text" placeholder="Weight" />
                  <input type="text" placeholder="Height" />
                  <input type="text" placeholder="Width" />
                  <input type="text" placeholder="Length" />
                </div>
                <div className="input-second">
                  <div>
                    <input
                      type="radio"
                      id="Fragile"
                      name="fav_language"
                      value="Fragile"
                    />
                      <label htmlFor="Fragile">Fragile</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="Express Delivery"
                      name="fav_language"
                      value="Express Delivery"
                    />
                      <label htmlFor="Express Delivery">Express Delivery </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="Insurance"
                      name="fav_language"
                      value="Insurance"
                    />
                      <label htmlFor="Insurance">Insurance</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="packaging"
                      name="fav_language"
                      value="packaging"
                    />
                      <label htmlFor="packaging">packaging</label>
                  </div>
                </div>

                <div className="btn-main">
                  <button className="submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inquiry;
