import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { BsStopwatchFill } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import "../css/news.css";

function News() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="news-title">
            <h2>
              Read Our <span>Latest News</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
              ducimus quas, a blanditiis necessitatibus omnis eum unde magni
              illum voluptates dolore, inventore saepe possimus eveniet nemo
              laudantium totam perferendis quam fugit impedit perspiciatis?
              Dolorem, harum. Praesentium architecto rerum veritatis! Quod
              dolor, nam eum aut sunt incidunt recusandae consectetur. Neque,
              voluptas!
            </p>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>

      <div className="row my-5">
        <div className="col-md-6">
          <div className="news">
            <img src="/img/news-image.png" alt="" />
            <div className="row my-3">
              <div className="col-md-4">
                <div className="news-attributes d-flex justify-content-center">
                  <FaCalendarAlt />
                  <p>Date 20/March/2023</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="news-attributes d-flex justify-content-center">
                  <BsStopwatchFill />
                  <p>10 Hours later</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="news-attributes d-flex justify-content-center">
                  <AiFillLike />
                  <AiFillDislike />
                </div>
              </div>
            </div>
            <h2>Importers Achieves Saving Through Logistic First Sale Rule</h2>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore
              nam inventore minima eum voluptatibus placeat tempora numquam
              praesentium commodi suscipit!
            </p>
          </div>
          <button className="news-btn">
            Read More <IoIosArrowForward />
          </button>
        </div>
        
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="news-2">
                <img src="/img/news-image.png" alt="" />
                <div className="row my-3">
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <FaCalendarAlt />
                      <p>20/March/2023</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <BsStopwatchFill />
                      <p>10 Hours later</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <AiFillLike />
                      <AiFillDislike />
                    </div>
                  </div>
                </div>
                <h2>
                  Importers Achieves Saving Through Logistic First Sale Rule
                </h2>
              </div>
              <button className="news-btn-2">
                Read More <IoIosArrowForward />
              </button>
            </div>
            <div className="col-md-6">
              <div className="news-2 ">
                <img src="/img/news-image.png" alt="" />
                <div className="row my-3">
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <FaCalendarAlt />
                      <p>20/March/2023</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <BsStopwatchFill />
                      <p>10 Hours later</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <AiFillLike />
                      <AiFillDislike />
                    </div>
                  </div>
                </div>
                <h2>
                  Importers Achieves Saving Through Logistic First Sale Rule
                </h2>
              </div>
              <button className="news-btn-2">
                Read More <IoIosArrowForward />
              </button>
            </div>
            <div className="col-md-6">
              <div className="news-2">
                <img src="/img/news-image.png" alt="" />
                <div className="row my-3">
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <FaCalendarAlt />
                      <p>20/March/2023</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <BsStopwatchFill />
                      <p>10 Hours later</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <AiFillLike />
                      <AiFillDislike />
                    </div>
                  </div>
                </div>
                <h2>
                  Importers Achieves Saving Through Logistic First Sale Rule
                </h2>
              </div>
              <button className="news-btn-2">
                Read More <IoIosArrowForward />
              </button>
            </div>
            <div className="col-md-6">
              <div className="news-2">
                <img src="/img/news-image.png" alt="" />
                <div className="row my-3">
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <FaCalendarAlt />
                      <p>20/March/2023</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <BsStopwatchFill />
                      <p>10 Hours later</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="news-attributes-2 d-flex justify-content-center">
                      <AiFillLike />
                      <AiFillDislike />
                    </div>
                  </div>
                </div>
                <h2>
                  Importers Achieves Saving Through Logistic First Sale Rule
                </h2>
              </div>
              <button className="news-btn-2">
                Read More <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
