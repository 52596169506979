import React from "react";
import "../css/testimonial.css";

function Testimonial() {
  const accordionBtns = document.querySelectorAll(".accordion");

  accordionBtns.forEach((accordion) => {
    accordion.onclick = function () {
      this.classList.toggle("is-open");

      let content = this.nextElementSibling;
      console.log(content);

      if (content.style.maxHeight) {
        //this is if the accordion is open
        content.style.maxHeight = null;
      } else {
        //if the accordion is currently closed
        content.style.maxHeight = content.scrollHeight + "px";
        console.log(content.style.maxHeight);
      }
    };
  });

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6">
          <div className="testimonial my-4">
            <h2>
              FREQUENTLY ASKED <span>QUESTIONS</span>
            </h2>
            <p>Do you have a questions? Read our FAQs</p>

            <button className="accordion">
              Website Design and Development
            </button>
            <div className="accordion-content">
              <p>
                Whether you need a wordpress website, a shopify site, or a
                custom fullstack application, we got you! No matter what kind of
                website or application you need, it will be made with clean and
                maintable code that follows modern development standards. We
                also have top notch designers that can make unique designs that
                will make your website look different and unique. Not to mention
                that we also provide 24/7 website maintenance so that you get
                all the support you need.
              </p>
            </div>

            <button className="accordion">
              Website Analytics and Performance
            </button>
            <div className="accordion-content">
              <p>
                We believe that in order to have a successful website, you need
                to constantly adjust and adapt to the data provided by your
                website visitors. Here at Pierre Web Development, we have
                narrowed down the specific key performance indicators that will
                dramatically boost your success with connecting to target
                markets. We will provide a basic metric dashboard based on how
                much traffic your site gets, detailed analytical reports that
                show which parts of your website is the most popular among
                visitors as well as access to tools you can use to make
                meaningful decisions based on this data.
              </p>
            </div>

            <button className="accordion">Digital Marketing</button>
            <div className="accordion-content">
              <p>
                We know that every great website focuses on helping you get more
                business and building a brand that your ideal customers will
                love and support. We can help you set up a great, SEO-focused
                content strategy, a paid ads campaign, email marketing
                integration with Mailchimp as well as a social media marketing
                campaign. We also use popular website analytic tools to track
                your site's performance and provide you with weekly analytic
                reports to help bolster your growth.
              </p>
            </div>
            <button className="accordion">Digital Marketing</button>
            <div className="accordion-content">
              <p>
                We know that every great website focuses on helping you get more
                business and building a brand that your ideal customers will
                love and support. We can help you set up a great, SEO-focused
                content strategy, a paid ads campaign, email marketing
                integration with Mailchimp as well as a social media marketing
                campaign. We also use popular website analytic tools to track
                your site's performance and provide you with weekly analytic
                reports to help bolster your growth.
              </p>
            </div>
            <button className="accordion">Digital Marketing</button>
            <div className="accordion-content">
              <p>
                We know that every great website focuses on helping you get more
                business and building a brand that your ideal customers will
                love and support. We can help you set up a great, SEO-focused
                content strategy, a paid ads campaign, email marketing
                integration with Mailchimp as well as a social media marketing
                campaign. We also use popular website analytic tools to track
                your site's performance and provide you with weekly analytic
                reports to help bolster your growth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          
        <div className="testimonial-2 my-4">
            <h2>
              TESTI <span>MONIAL</span>
            </h2>
            <p>Add Your Reviews And Experience This Company</p>
            
            <div className="cards">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                     <div className="card-img">
                      <img src="/img/men.jpg" alt="" />
                     </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-texts">
                       <h4>Rahul Gautam</h4>
                       <span>Expert</span>
                       <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, est! Deleniti facere nisi molestias accusantium!</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="cards">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                     <div className="card-img">
                      <img src="/img/men.jpg" alt="" />
                     </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-texts">
                       <h4>Rahul Gautam</h4>
                       <span>Expert</span>
                       <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, est! Deleniti facere nisi molestias accusantium!</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="cards">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                     <div className="card-img">
                      <img src="/img/men.jpg" alt="" />
                     </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-texts">
                       <h4>Rahul Gautam</h4>
                       <span>Expert</span>
                       <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, est! Deleniti facere nisi molestias accusantium!</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
          
        </div>
      </div>
    </div>
  );
}

// render(<Testimonial />);
export default Testimonial;
