import React, { useState } from "react";
import TopHeader from "./TopHeader";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/signin.css";

function Signup() {
  const navigation = useNavigate();
  const [phone, setPhone] = useState();

  const inputStyle = {
    color: "grey",
    backgroundColor: "#e9f3fa",
    border: "none",
    // padding: '22px 0px',
    outline: "none",
    borderRadius: " 10px",
    height: "42px",
  };
  const buttonStyle = {
    backgroundColor: "#e9f3fa",
    outline: "none",
    border: "none",
    borderRadius: " 10px",
  };
  const dropdownStyle = {
    backgroundColor: "#e9f3fa",
  };
  return (
    <>
      <TopHeader />
      <NavHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="signup">
              <h1 className="my-4 signup-title">SIGNUP</h1>
              <div className="form">
                <div className="signup-input-fields  my-1">
                  <div className="signup-input-single  my-1">
                    <label htmlFor="fname" className="mb-1">
                      First Name
                    </label>
                    <input type="text" id="fname" placeholder="First Name" />
                  </div>

                  <div className="signup-input-single  my-1">
                    <label htmlFor="lname" className="mb-1">
                      Last Name
                    </label>
                    <input type="text" id="lname" placeholder="Last Name" />
                  </div>
                </div>

                <div className="signup-input-fields  my-1">
                  <div className="signup-input-single my-1">
                    <label htmlFor="email" className="mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="coolrunning@gmail.com"
                    />
                  </div>

                  <div className="signup-input-single  my-1">
                    <label htmlFor="phone" className="mb-1">
                      Phone
                    </label>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(data) => setPhone(data)}
                      inputStyle={inputStyle}
                      buttonStyle={buttonStyle}
                      dropdownStyle={dropdownStyle}
                    />
                  </div>
                </div>

                <div className="signup-input-fields  my-1">
                  <div className="signup-input-single my-1">
                    <label htmlFor="password" className="mb-1">
                      Password
                    </label>
                    <input
                      type="text"
                      id="password"
                      placeholder="***********"
                    />
                  </div>

                  <div className="signup-input-single  my-1">
                    <label htmlFor="cpassword" className="mb-1">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      id="cpassword"
                      placeholder="***********"
                    />
                  </div>
                </div>

                <div className="signup-input-fields  my-1">
                  <div className="signup-remember-me my-1">
                    <label htmlFor="password" className="mb-1">
                      By creating an account, you agree to our{" "}
                      <span>Term and Policy</span>
                    </label>
                    <input type="checkbox" id="password" />
                  </div>

                  <div className="signup-forget  my-1">
                    <p>Forgot Password</p>
                  </div>
                </div>

                <button className="signup-button">SIGNUP</button>

                <div className="already-account">
                  <h6>
                    Already have an Account{" "}
                    <span onClick={() => navigation("/login")}>Click Here</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
