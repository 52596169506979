import React from "react";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import '../css/filtertable.css'

function FIlterTable() {
    const navigation =useNavigate()
  return (
    <div>
      <Table responsive="md" borderless={true} >
        <thead>
          <tr>
            <th>ITEMS</th>
            <th>STATUS</th>
            <th>TOTAL</th>
            <th>SERVICE</th>
            <th>DETAILS</th>
          </tr>
        </thead>
        <tbody>
        {Array.from({ length: 12 }).map((_, index) => (
           <tr key={index}>
           <td>
               <div className="items">
                   <img src="/img/shoes.jpg" alt="" />
                   <div className="item-2">
                       <p>Leather Boot</p>
                       <span>20 PCS</span>
                   </div>
               </div>
           </td>
           <td>
               <div className="status">
                   <p>6 Day left</p>
                   <span>Pending</span>
               </div>
           </td>
           <td>
               <h5>$ 8.3400</h5>
           </td>
           <td>
               <h5>Cargo Services</h5>
           </td>
           <td>
               <button onClick={()=>navigation('/order-details')} className="order-details-button" >Order Details</button>
           </td>
         </tr>
          ))}
         
        
        </tbody>
      </Table>
    </div>
  );
}

export default FIlterTable;
