import React from 'react'
import {FiPhoneCall} from 'react-icons/fi'
import {IoMdMail} from 'react-icons/io'
import {MdLocationPin} from 'react-icons/md'
import '../css/navbar.css'

function TopHeader() {
  return (
  <div className="top-bar">
      <div className='container' >
      <div className="d-flex justify-content-start">
        <div className="top-first">
           <FiPhoneCall/>
           <span>+1 (225) 235-5657</span>
        </div>
        
        <div className="top-second">
           <IoMdMail/>
           <span>coolrunning@gmail.com</span>
        </div>
        
        <div className="top-third">
           <MdLocationPin/>
           <span>USA</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TopHeader
