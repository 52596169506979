import React, { useState } from "react";
import TopHeader from "./TopHeader";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/login.css";

function Login() {
  const navigation = useNavigate();
  const [phone, setPhone] = useState();

  const inputStyle = {
    color: "grey",
    backgroundColor: "#e9f3fa",
    border: "none",
    // padding: '22px 0px',
    outline: "none",
    borderRadius: " 10px",
    height: "42px",
  };
  const buttonStyle = {
    backgroundColor: "#e9f3fa",
    outline: "none",
    border: "none",
    borderRadius: " 10px",
  };
  const dropdownStyle = {
    backgroundColor: "#e9f3fa",
  };
  return (
    <>
      <TopHeader />
      <NavHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="login">
              <h1 className="my-4 login-title">LOGIN</h1>
              <div className="form">
                <div className="login-input-fields  my-1">
                  <div className="login-input-single my-1">
                    <label htmlFor="email" className="mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="coolrunning@gmail.com"
                    />
                  </div>
                </div>

                <div className="login-input-fields  my-1">
                  <div className="login-input-single  my-1">
                    <label htmlFor="phone" className="mb-1">
                      Phone
                    </label>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(data) => setPhone(data)}
                      inputStyle={inputStyle}
                      buttonStyle={buttonStyle}
                      dropdownStyle={dropdownStyle}
                    />
                  </div>
                </div>

                <div className="login-input-fields  my-1">
                  <div className="login-input-single my-1">
                    <label htmlFor="password" className="mb-1">
                      Password
                    </label>
                    <input
                      type="text"
                      id="password"
                      placeholder="***********"
                    />
                  </div>
                </div>

                <div className="login-input-fields  my-1">
                  <div className="login-remember-me my-1">
                    <label htmlFor="password" className="mb-1">
                      Remember Me
                    </label>
                    <input type="checkbox" id="password" />
                  </div>

                  <div className="login-forget  my-1">
                    <p>Forgot Password</p>
                  </div>
                </div>

                <button className="login-button">LOGIN</button>

                <div className="already-account">
                  <h6>
                    Create New Account{" "}
                    <span onClick={() => navigation("/signup")}>
                      Click here
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
