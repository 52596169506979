import React from "react";
import TopHeader from "../components/TopHeader";
import NavHeader from "../components/NavHeader";
import { BsFillCircleFill } from "react-icons/bs";
import ShippingService from "./ShippingService";
import Footer from '../components/Footer'
import "../css/neworder.css";

function NewOrder() {
  return (
    <>
      <TopHeader />
      <NavHeader />
      <div className="container">
        <h1 className="add-new-prder-title">Add New Order</h1>
        <div className="row">
          <div className="col-md-6">
            <div className="pickup">
              <h5>
                <BsFillCircleFill className="pickup-circle" /> Pick Up Address
              </h5>
              <div className="form">
                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="fname">First Name</label>
                    <input type="text" id="fname" placeholder="First Name" />
                  </div>
                  <div className="input-single">
                    <label htmlFor="lname">Last Name</label>
                    <input type="text" id="lname" placeholder="Last Name" />
                  </div>
                </div>
                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="fname">Phone Number</label>
                    <input type="text" id="fname" placeholder="Phone Number" />
                  </div>
                  <div className="input-single">
                    <label htmlFor="lname">Alternative Phone Number</label>
                    <input
                      type="text"
                      id="lname"
                      placeholder="Alternative Phone Number"
                    />
                  </div>
                </div>
                <div className="single-input">
                  <label htmlFor="Address">Enter Address</label>
                  <textarea
                    className="neworder-textarea"
                    type="Type something..."
                    id="Address"
                  />
                </div>

                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="State">State</label>
                    <input type="text" id="State" placeholder="State" />
                  </div>

                  <div className="input-single">
                    <label htmlFor="City">City</label>
                    <select className="neworder-textarea" name="cars" id="cars">
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>

                <div className="input-fields">
                  <div className="input-single">
                    <label for="appt">Select a time:</label>
                    <input type="time" id="appt" name="appt" />
                  </div>

                  <div className="input-single">
                    <label htmlFor="Date">Date</label>
                    <input type="date" placeholder="Date" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="delivery">
              <h5>
                <BsFillCircleFill className="delivery-circle" />
                Delivery Address
              </h5>
              <div className="form">
                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="fname">First Name</label>
                    <input type="text" id="fname" placeholder="First Name" />
                  </div>
                  <div className="input-single">
                    <label htmlFor="lname">Last Name</label>
                    <input type="text" id="lname" placeholder="Last Name" />
                  </div>
                </div>
                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="fname">Phone Number</label>
                    <input type="text" id="fname" placeholder="Phone Number" />
                  </div>
                  <div className="input-single">
                    <label htmlFor="lname">Alternative Phone Number</label>
                    <input
                      type="text"
                      id="lname"
                      placeholder="Alternative Phone Number"
                    />
                  </div>
                </div>
                <div className="single-input">
                  <label htmlFor="Address">Enter Address</label>
                  <textarea
                    className="neworder-textarea"
                    type="Type something..."
                    id="Address"
                  />
                </div>

                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="State">State</label>
                    <input type="text" id="State" placeholder="State" />
                  </div>

                  <div className="input-single">
                    <label htmlFor="City">City</label>
                    <select className="neworder-textarea" name="cars" id="cars">
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>

                <div className="input-fields">
                  <div className="input-single">
                    <label htmlFor="City">Time</label>
                    <input type="time" placeholder="Select Time" />
                  </div>

                  <div className="input-single">
                    <label htmlFor="Date">Date</label>
                    <input type="date" placeholder="Date" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="add-new-order-second">
          <div className="row">
            <div className="col-md-6">
              <h4>Parcel-Category</h4>
              <div className="parcel-category">
                <img src="/img/parcel1.png" alt="" />
                <select className="neworder-textarea" name="cars" id="cars">
                  <option value="LETTER,ELECTRONICS ITEM, ETC">LETTER,ELECTRONICS ITEM ETC</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="parcel-size">
              <h4>Parcel-Size</h4>
                <img src="/img/parcel1.png" alt="" />
                <select className="neworder-textarea" name="cars" id="cars">
                  <option value="SIZE(L 12cm H 9cm W 20cm)">SIZE(L 12cm H 9cm W 20cm)</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <ShippingService/>
      </div>
      <Footer/>
    </>
  );
}

export default NewOrder;
