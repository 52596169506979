import React from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import "../css/options.css";

function Options() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/Order History.png" alt="" />
            <div className="options">
              <h3>Easy to order</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/No Hidden Fees.png" alt="" />
            <div className="options">
              <h3>No hidden cost </h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/Cash on Delivery.png" alt="" />
            <div className="options">
              <h3>Cash on delivery</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/Tracking.png" alt="" />
            <div className="options">
              <h3>Live Tracking </h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/E-commerce.png" alt="" />
            <div className="options">
              <h3>E-payment</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="options-main">
            <img src="/img/Holding Box.png" alt="" />
            <div className="options">
              <h3>Quick delivery</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, repudiandae! consectetur adipisicing elit. Suscipit,
                repudiandae!
              </p>
              <button className="btn">
                {" "}
                <BsArrowRightCircle />
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Options;
