import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillTwitterSquare } from "react-icons/ai";
import "../css/footer.css";

function Footer() {
 return (
  <>
  <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-main">
              <ul>
                <li>OFFICE ADDRESS</li>
                <li className="address">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt,
                  accusamus?
                </li>
                <li>
                  <FaPhoneAlt /> +1(209) 545 5656
                </li>
                <li>
                  {" "}
                  <MdEmail className="message-icon" /> coolrunning@gmail.com
                </li>
                <li>
                  {" "}
                  <ImLocation2 className="location-icon" />
                  USA
                </li>
                <li>
                  Connected
                  <AiFillFacebook className="social-icon" />
                  <AiFillInstagram className="social-icon" />
                  <AiFillTwitterSquare className="social-icon" />
                </li>
                <li>Details Page</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-main">
              <ul>
                <li>USEFULL LINK</li>
                <li>Home</li>
                <li>About</li>
                <li>Contact us</li>
                <li>Wishlisst</li>
                <li>Checkout</li>
                <li>Details Page</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-main">
              <ul>
                <li>OUR STORES</li>
                <li>Home</li>
                <li>About</li>
                <li>Contact us</li>
                <li>Wishlisst</li>
                <li>Checkout</li>
                <li>Details Page</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-main">
              <ul>
                <li>PURCHASE INFO</li>
                <li>Home</li>
                <li>About</li>
                <li>Contact us</li>
                <li>Wishlisst</li>
                <li>Checkout</li>
                <li>Details Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-bottom-first">
                <p>Copyright 2023. All Rights Reserved Cyberx</p>
              </div>
            </div>
            <div className="col-md-8">
               <div className="footer-bottom-second">
                <img src="/img/footer-img.jpg" alt="" />
               </div>
            </div>
          </div>
        </div>
      </div>
  </>
 )
}

export default Footer;
