import React from "react";
import NavHeader from "./NavHeader";
import TopHeader from "./TopHeader";
import Slider from "./Slider";
import Stepper from "../pages/Stepper";
import OrdersCategories from "./OrdersCategories";
import Options from "./Options";
import Inquiry from "./Inquiry";
import News from "./News";
import Footer from "./Footer";
import Testimonial from "./Testimonial";

function Home() {
  return (
    <div>
      <TopHeader />
      <NavHeader />
      <Slider />
      <OrdersCategories />
      {/* <Stepper/> */}
      <Options />
      <Inquiry />
      <News />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Home;
